<template>
    <div>
        <Header
            :title="data.home_title_nl"
            :text="data.home_text_nl"
            :image="cdnurl(data.home_image)"
            :image_title="data.home_image_title_nl"
            type="large"
            :actions="[
                {
                    title: 'Onze projecten',
                    to: 'Projecten',
                    class: 'mb-r'
                },
                {
                    title: 'Gratis dakscan',
                    to: { name: 'Contact', params: { initialmode: 'scan' }},
                    class: 'bg-accent',
                },
            ]"
        />

        <Layout>
            <template v-slot:top>
                <div class="accentlink s-up:w-10 m-up:w-8 l-up:w-6 xl-up:w-5 mb-xxl mt-xxl">
                    <h2 class="w-10">{{ data.about_title_nl }}</h2>
                    <div class="color-secondary">
                        <span v-html="data.about_text_nl" class="mr-s" />
                        <router-link :to="{ name: 'Over Ons' }">Lees meer &raquo;</router-link>
                    </div>
                </div>
            </template>
            <template v-slot:content>
                <Services :list="data.services"/>
            </template>
            <template v-slot:sidebar>
                <Sidebar type="home" />
            </template>
        </Layout>
    </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Services from '@/components/Services.vue';
import Layout from '@/components/Layout-Sidebar.vue';
import Sidebar from '@/components/Sidebar.vue';

export default {
    name: 'Home',
    components: {
        Header,
        Services,
        Sidebar,
        Layout,
    },
    computed: {
        data() {
            return this.$store.getters.data;
        },
    },
    methods: {
        setMeta() {
            if (this.data && this.data.settings && this.data.settings[0]) {
                this.$store.commit('setMeta', {
                    data: {
                        titleDefault: this.data.settings[0].meta_title_nl,
                    },
                });
            }
        },
    },
    watch: {
        data: {
            handler: 'setMeta',
            immediate: true,
        },
    },
};
</script>
