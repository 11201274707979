<template>
    <div class="services flex flex-wrap align-stretch">
        <Card
            v-for="service in list"
            :key="'service_' + service.id"
            class="shadow-l"
            :title="service.name"
            :text="service.subtitle_nl"
            :image="cdnurl(service.intro_image)"
            imageRatio="ratio-2by1"
            :action="{ title: 'Lees meer', class: 'bg-accent -s', to: '/diensten/' + service.id + '/' + kebabcase(service.name) }"
            @click="$router.push('/diensten/' + service.id + '/' + kebabcase(service.name)).catch((e) => {});"
        />

        <ServiceCTAcard
            class="shadow-l"
        />
    </div>
</template>

<script>
import kebabcase from 'lodash.kebabcase';
import ServiceCTAcard from '@/components/ServiceCTACard.vue';

export default {
    name: 'Services',
    components: { ServiceCTAcard },
    props: {
        list: Array,
    },
    methods: {
        kebabcase,
    },
    computed: {
        data() {
            return this.$store.getters.data;
        },
    },
};
</script>

<style>
.services .card {
    flex: 1 1 35rem;
    margin-bottom: var(--size-l);
    margin-right: var(--size-l)
}
</style>
