var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Header',{attrs:{"title":_vm.data.home_title_nl,"text":_vm.data.home_text_nl,"image":_vm.cdnurl(_vm.data.home_image),"image_title":_vm.data.home_image_title_nl,"type":"large","actions":[
            {
                title: 'Onze projecten',
                to: 'Projecten',
                class: 'mb-r'
            },
            {
                title: 'Gratis dakscan',
                to: { name: 'Contact', params: { initialmode: 'scan' }},
                class: 'bg-accent',
            } ]}}),_c('Layout',{scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"accentlink s-up:w-10 m-up:w-8 l-up:w-6 xl-up:w-5 mb-xxl mt-xxl"},[_c('h2',{staticClass:"w-10"},[_vm._v(_vm._s(_vm.data.about_title_nl))]),_c('div',{staticClass:"color-secondary"},[_c('span',{staticClass:"mr-s",domProps:{"innerHTML":_vm._s(_vm.data.about_text_nl)}}),_c('router-link',{attrs:{"to":{ name: 'Over Ons' }}},[_vm._v("Lees meer »")])],1)])]},proxy:true},{key:"content",fn:function(){return [_c('Services',{attrs:{"list":_vm.data.services}})]},proxy:true},{key:"sidebar",fn:function(){return [_c('Sidebar',{attrs:{"type":"home"}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }